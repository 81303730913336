<template>
  <div class="block">
    <div class="row" :class="{ 'row--blue': seeker.relations.length > 0 }">
      <div class="cell">{{ seeker.id }}</div>
      <div class="cell cell__agent" :class="{'cell__agent--red': seeker.isResponsibleBlocked}">
        <i class="i-employees"></i>
        <div class="user__name">{{ seeker.responsibleName }}</div>
      </div>
      <div class="cell">
        <a :href="'tel:' + seeker.phone" @click.stop>
          <i class="ri-phone-fill"></i> {{ seeker.phone }}
          <span v-if="phonesCount > 1" style="vertical-align: super; font-size: smaller;">{{ phonesCount }}</span>
        </a>
      </div>
      <div class="cell cell__name">
        <div class="user__icon">{{ seeker.name[0] }}</div>
        <div class="user__name">{{ seeker.name }}
          <span v-if="seeker.relations.length > 1" style="vertical-align: super; font-size: smaller;">{{ seeker.relations.length }}</span>
        </div>
      </div>
      <div class="cell">
        <span v-if="seeker.type === localConstants.SEEKER_TYPES.COOPERATIVE">
          {{ seeker.street }}, {{ seeker.houseNo }}
        </span>
        <span v-else-if="seeker.type === localConstants.SEEKER_TYPES.ACTIVITY">{{ seeker.activity }}</span>
        <span v-else>{{ seeker.typeName }}</span>
      </div>
      <div class="cell">{{ seeker.lastCallAt | date }}</div>
      <div v-if="!seeker.isAgentBlocked" class="cell" @click.stop.prevent>
        <Multiselect
          class="mSelect-dropdown"
          :options="list"
          :searchable="false"
          :show-labels="false"
          :reset-after="true"
          :value="item"
          placeholder
          @select="actions"
        />
      </div>
    </div>
    <div class="card" :class="{ 'card--blue': seeker.relations.length > 0 }">
      <div class="card__row">
        <div class="card__cell">
          <div>ID {{ seeker.id }}</div>
        </div>
        <div class="card__cell card__cell__agent" :class="{'card__cell__agent--red': seeker.isResponsibleBlocked}">
          <i class="i-employees"></i>
          <div class="user__name">{{ seeker.responsibleName }}</div>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--phone">
          <a :href="'tel:' + seeker.phone" @click.stop>
            <i class="ri-phone-fill"></i> {{ seeker.phone }}
            <span v-if="phonesCount > 1" style="vertical-align: super; font-size: smaller;">{{ phonesCount }}</span>
          </a>
        </div>
        <div class="card__cell">
          <div class="user__icon">{{ seeker.name[0] }}</div>
          <div class="user__name">{{ seeker.name }}
            <span v-if="seeker.relations.length > 1" style="vertical-align: super; font-size: smaller;">{{ seeker.relations.length }}</span>
          </div>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Звідки</div>
        <div class="card__cell">
          <span v-if="seeker.type === localConstants.SEEKER_TYPES.COOPERATIVE">
            {{ seeker.street }}, {{ seeker.houseNo }}
          </span>
          <span v-else-if="seeker.type === localConstants.SEEKER_TYPES.ACTIVITY">{{ seeker.activity }}</span>
          <span v-else>{{ seeker.typeName }}</span>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Останній дзвінок</div>
        <div class="card__cell card__cell--datetime">
          <i class="ri-calendar-fill"></i>
          <span v-if="seeker.lastCallAt" class="card__cell-date">{{ seeker.lastCallAt | date }}</span>
          <span v-if="!seeker.lastCallAt" class="card__cell-date">―</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ModalUploadSeeker from '@/views/Seekers/Upload'
import localConstants from '@/utils/localConstants'
import {mapGetters} from 'vuex'

export default {
  name: 'Seeker',
  props: ['seeker'],
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(['user']),
    phonesCount() {
      return this.seeker.contacts.reduce((previous, current) => current.phones.length + previous, 0)
    }
  },
  data: () => ({
    list: ['Редагувати'],
    item: '',
    localConstants
  }),
  created() {
    if (this.user.role === localConstants.USER_ROLES.ADMIN) {
      this.list.push('Видалити')
    }
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        const style = this.$modal.styles
        this.$modal.show(
          ModalUploadSeeker,
          {seeker: this.seeker},
          {...style, name: 'modalUploadSeeker'},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (option === 'Видалити') {
        this.$store.dispatch('seekers/delete', this.seeker.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
