var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "seekers__wrapper screen__wrapper" },
        _vm._l(_vm.seekers, function(seeker, i) {
          return _c("Seeker", {
            key: seeker.id,
            attrs: { seeker: seeker },
            nativeOn: {
              click: function($event) {
                return _vm.info(seeker)
              }
            }
          })
        }),
        1
      ),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.loadSeekers } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "seekers__table table__titles" }, [
      _c("div", { staticClass: "table__title" }, [_vm._v("ID")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Агент")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Телефон")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Шукач")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Звідки")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Ост. дзвінок")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }