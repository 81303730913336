<template>
  <div class="">
    <div class="seekers__table table__titles">
      <div class="table__title">ID</div>
      <div class="table__title">Агент</div>
      <div class="table__title">Телефон</div>
      <div class="table__title">Шукач</div>
      <div class="table__title">Звідки</div>
      <div class="table__title">Ост. дзвінок</div>
    </div>
    <div class="seekers__wrapper screen__wrapper">
      <Seeker v-for="(seeker, i) in seekers" :key="seeker.id" :seeker="seeker" @click.native="info(seeker)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadSeekers"></infinite-loading>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Seeker from './Seeker'
import InfiniteLoading from 'vue-infinite-loading'
import InfoSeeker from '@/views/Seekers/Info'

export default {
  name: 'Seekers',
  components: {
    Seeker,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      seekers: 'seekers/seekers',
      pagination: 'seekers/pagination',
      page: 'seekers/page',
    }),
    hasMoreResults() {
      return this.seekers.length < this.pagination?.totalCount && this.page > 1
    },
  },
  methods: {
    info(seeker) {
      this.$modal.display(InfoSeeker, {seeker}, {name: 'InfoSeeker', classes: 'modal modal-action',})
    },
    async loadSeekers() {
      this.isLoading = true
      try {
        await this.$store.dispatch('seekers/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
